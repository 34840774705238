import uuidv4 from "uuid/v4";
// import { storage } from "utils/firebase";
import lambda from "utils/lambda";

let storage;

export const getAsset = async fullPath => {
    return fullPath;
};

export const deleteLogo = async (path) => {
    const [logo] = path.split("/").splice(-1);
    const res = await fetch(
        lambda.getUrl("deleteLogo"),
        {
            method: "POST",
            body: JSON.stringify({ logo }),
        }
    );
};

export const uploadLogo = async (logo, programme) => {
    const data = new FormData();

    data.append("file", logo);

    const res = await fetch(
        lambda.getUrl("uploadLogo"),
        {
            method: "POST",
            body: data,
            headers: {
                accept: "text/html",
            }
        }
    );

    const json = await res.json();

    return json.Location;
};
