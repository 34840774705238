import React from "react";
import PropTypes from "prop-types";

import Input from "components/Forms/Elements/Input";
import TextArea from "components/Forms/Elements/TextArea";
import Checkbox from "components/Forms/Elements/Checkbox";
import Button from "components/Button";

const FactFind = props => {
  const handleChange = e => {
    if (e.target.name === "factFind.activities") {
      props.onChange({
        target: {
          name: e.target.name,
          value: e.target.checked
            ? [...props.fields.factFind.activities, e.target.value]
            : props.fields.factFind.activities.filter(a => a !== e.target.value)
        }
      });
    } else {
      props.onChange(e);
    }
  };

  return (
    <section className="fact-find">
      <form className="fact-find-form" onSubmit={props.onSubmit}>
        {props.fields.level === "standard" && (
          <React.Fragment>
            <fieldset className="marketing-activities">
              <legend>Marketing Activity</legend>

              <div className="activities">
                {props.activities.map(activity => (
                  <Checkbox
                    key={activity.id}
                    id={activity.id}
                    name="factFind.activities"
                    label={activity.name}
                    value={activity.id}
                    checked={props.fields.factFind.activities.includes(
                      activity.id
                    )}
                    onChange={handleChange}
                  />
                ))}
              </div>
            </fieldset>

            <Input
              type="text"
              id="usp"
              name="factFind.usp"
              label="Unique Selling Point (USP)"
              value={props.fields.factFind.usp}
              onChange={handleChange}
            />

            <Input
              type="text"
              id="goals"
              name="factFind.goals"
              label="Goal/s for the year ahead"
              value={props.fields.factFind.goals}
              onChange={handleChange}
            />
          </React.Fragment>
        )}

        <TextArea
          type="text"
          id="notes"
          name="factFind.notes"
          label="Notes"
          value={props.fields.factFind.notes}
          onChange={handleChange}
        />

        <div className="form-actions">
          <div className="row">
            <div className="col">
              <Button
                type="button"
                secondary
                onClick={props.onPrevStep}
                disabled={props.submitting}
              >
                Back
              </Button>
            </div>
            <div className="col">
              <Button type="submit" disabled={props.submitting}>
                {props.submitting ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

FactFind.propTypes = {
  fields: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  activities: PropTypes.array.isRequired
};

export default FactFind;
