const defaultFields = {
  accountManager: null,
  address: {
    line1: "",
    line2: "",
    town: "",
    county: "",
    postcode: "",
    country: ""
  },
  budget: null,
  industry: null,
  level: "",
  name: "",
  phone: "",
  primary: null,
  website: "",
  contacts: [],
  factFind: {
    activities: [],
    goals: "",
    notes: "",
    usp: ""
  },
  draft: true
};

export default defaultFields;
