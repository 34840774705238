import React from "react";
import classNames from "classnames";

import "./Loading.scss";

const Loading = ({ full, short, small }) => {
  return (
    <div className={classNames("loading-wrap", { full, short, small })}>
      <div className="loader">
        <div className="rect-1" />
        <div className="rect-2" />
        <div className="rect-3" />
        <div className="rect-4" />
        <div className="rect-5" />
      </div>
    </div>
  );
};

export default Loading;
