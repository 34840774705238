import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { SnackbarProvider } from "notistack";

import Loading from "components/Loading";

import client from "utils/apollo";
import { Provider as AppProvider } from "utils/AppContext";
import { Provider as UserProvider } from "utils/UserContext";
import { Provider as ProgrammeProvider } from "utils/ProgrammeContext";
import LoggedInRoute from "utils/routes/LoggedInRoute";

import OnlineBusinessForm from "pages/OnlineBusinessForm";
import OnlineBookingForm from "pages/OnlineBookingForm";
const Index = lazy(() => import("pages"));
const Login = lazy(() => import("pages/Login"));
const Reports = lazy(() => import("pages/Reports"));

const LoggedInRoutes = () => (
  <UserProvider>
    <ProgrammeProvider>
      <Router>
        <Suspense fallback={<Loading full />}>
          <Switch>
            <Route path="/login" component={Login} />
            <LoggedInRoute path="/reports/:id" component={Reports} />
            <LoggedInRoute component={Index} />
          </Switch>
        </Suspense>
      </Router>
    </ProgrammeProvider>
  </UserProvider>
);

const App = () => {
  return (
    <ApolloProvider client={client}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
      >
        <AppProvider>
          <Router>
            <Switch>
              <Route path="/business-form/:id" component={OnlineBusinessForm} />
              <Route path="/online-booking/:id" component={OnlineBookingForm} />

              <LoggedInRoutes />
            </Switch>
          </Router>
        </AppProvider>
      </SnackbarProvider>
    </ApolloProvider>
  );
};

export default App;
