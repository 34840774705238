import React from "react";
import PropTypes from "prop-types";
import Input from "components/Forms/Elements/Input";
import Select from "components/Forms/Elements/Select";
import salutations from "utils/salutations.json";

const Form = props => {
  return (
    <form onSubmit={props.onSubmit} className="medium-form">
      <Select
        id="salutation"
        name="salutation"
        label="Salutation"
        value={props.fields.salutation}
        onChange={props.onChange}
        options={salutations}
        error={props.errors.includes("salutation")}
        leftAlign
      />

      <Input
        type="text"
        id="firstName"
        name="firstName"
        label="First Name"
        value={props.fields.firstName}
        onChange={props.onChange}
        error={props.errors.includes("firstName")}
        leftAlign
      />

      <Input
        type="text"
        id="lastName"
        name="lastName"
        label="Last Name"
        value={props.fields.lastName}
        onChange={props.onChange}
        error={props.errors.includes("lastName")}
        leftAlign
      />

      <Select
        id="jobTitle"
        name="jobTitle"
        label="Job Title"
        value={props.fields.jobTitle}
        onChange={props.onChange}
        error={props.errors.includes("jobTitle")}
        options={props.jobTitles.map(({ name, id }) => ({
          label: name,
          value: id
        }))}
        leftAlign
      />

      <Input
        type="tel"
        id="phone"
        name="phone"
        label="Direct Line"
        value={props.fields.phone}
        onChange={props.onChange}
        error={props.errors.includes("phone")}
        leftAlign
      />

      <Input
        type="tel"
        id="mobile"
        name="mobile"
        label="Mobile"
        value={props.fields.mobile}
        onChange={props.onChange}
        error={props.errors.includes("mobile")}
        leftAlign
      />

      <Input
        type="email"
        id="email"
        name="email"
        label="Email"
        value={props.fields.email}
        onChange={props.onChange}
        error={props.errors.includes("email")}
        leftAlign
      />

      {props.actions}
    </form>
  );
};

Form.propTypes = {
  fields: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  jobTitles: PropTypes.array.isRequired
};

export default Form;
