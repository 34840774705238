import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactSelect from "react-select";

import customStyles from "./styles";

const Select = ({
  label,
  error,
  options,
  leftAlign,
  value,
  onChange,
  small,
  centered,
  ...atts
}) => {
  const classes = classNames("form-element", "select", { error, leftAlign });

  const handleChange = (values, field) => {
    const { name } = field;
    const value = field.action === "clear" ? null : values.value;
    const event = { target: { type: "select", name, value } };
    onChange(event);
  };

  return (
    <div className={classes}>
      {label && <label htmlFor={atts.id}>{label}</label>}
      <ReactSelect
        className={classNames("element", atts.className)}
        styles={customStyles(error, small, centered)}
        options={options}
        value={options.filter(option => option.value === value)}
        onChange={handleChange}
        {...atts}
      />
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  error: PropTypes.bool,
  leftAlign: PropTypes.bool
};

export default Select;
