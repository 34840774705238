import React from "react";
import PropTypes from "prop-types";

import Input from "components/Forms/Elements/Input";
import Select from "components/Forms/Elements/Select";
import Button from "components/Button";
import countries from "utils/countries.json";

const Form = props => {
  return (
    <form className="business-details" onSubmit={props.onSubmit}>
      <div className="row">
        <div className="col">
          <Input
            type="text"
            id="name"
            name="name"
            label="Business Name"
            value={props.fields.name}
            onChange={props.onChange}
            onBlur={props.onBusinessNameChange}
            warning={props.duplicate}
            error={props.errors.includes("name")}
            leftAlign
          />

          <Input
            type="text"
            id="addressLine1"
            name="address.line1"
            label="Address"
            value={props.fields.address.line1}
            onChange={props.onChange}
            error={props.errors.includes("address.line1")}
            leftAlign
          />
          <Input
            type="text"
            id="addressLine2"
            name="address.line2"
            value={props.fields.address.line2}
            onChange={props.onChange}
            error={props.errors.includes("address.line2")}
            leftAlign
          />
          <Input
            type="text"
            id="town"
            name="address.town"
            label="Town"
            value={props.fields.address.town}
            onChange={props.onChange}
            error={props.errors.includes("address.town")}
            leftAlign
          />
          <Input
            type="text"
            id="county"
            name="address.county"
            label="County"
            value={props.fields.address.county}
            onChange={props.onChange}
            error={props.errors.includes("address.county")}
            leftAlign
          />
          <Input
            type="text"
            id="postcode"
            name="address.postcode"
            label="Postcode"
            value={props.fields.address.postcode}
            onChange={props.onChange}
            error={props.errors.includes("address.postcode")}
            leftAlign
          />
          <Select
            id="country"
            name="address.country"
            label="Country"
            value={props.fields.address.country}
            onChange={props.onChange}
            error={props.errors.includes("address.country")}
            options={countries}
            leftAlign
          />
        </div>
        <div className="col">
          <Input
            type="tel"
            id="phone"
            name="phone"
            label="Phone"
            value={props.fields.phone}
            onChange={props.onChange}
            error={props.errors.includes("phone")}
            leftAlign
          />
          <Select
            id="level"
            name="level"
            label="Level"
            value={props.fields.level}
            onChange={props.onChange}
            error={props.errors.includes("level")}
            options={props.levels}
            leftAlign
          />

          <Select
            id="budget"
            name="budget"
            label="Budget"
            value={props.fields.budget}
            onChange={props.onChange}
            error={props.errors.includes("budget")}
            options={props.budgets.map(({ name, id }) => ({
              label: name,
              value: id
            }))}
            leftAlign
          />

          <Select
            id="industry"
            name="industry"
            label="Industry"
            value={props.fields.industry}
            onChange={props.onChange}
            error={props.errors.includes("industry")}
            options={props.industries.map(({ name, id }) => ({
              label: name,
              value: id
            }))}
            leftAlign
          />

          <Select
            id="accountManagers"
            name="accountManager"
            label="Account Manager"
            value={props.fields.accountManager}
            onChange={props.onChange}
            error={props.errors.includes("accountManager")}
            options={props.accountManagers.map(({ fullName, id }) => ({
              label: fullName,
              value: id
            }))}
            leftAlign
          />

          <Input
            type="text"
            id="website"
            name="website"
            label="Website"
            value={props.fields.website}
            onChange={props.onChange}
            error={props.errors.includes("website")}
            leftAlign
          />
        </div>
      </div>

      <div className="form-actions">
        <div className="row">
          <div className="col" />
          <div className="col">
            <Button type="submit">Next</Button>
          </div>
        </div>
      </div>
    </form>
  );
};

Form.propTypes = {
  fields: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onBusinessNameChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  duplicate: PropTypes.bool.isRequired,
  levels: PropTypes.array.isRequired,
  budgets: PropTypes.array.isRequired,
  industries: PropTypes.array.isRequired,
  accountManagers: PropTypes.array.isRequired,
  programme: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

export default Form;
