import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import NewButton from "components/NewButton";
import Button from "components/Button";
import { formatDialCode } from "utils/format";
import { REQUIRED_FIELDS } from "utils/messages";
import Form from "./Form";
import { validateContact } from "../validate";

const Contacts = props => {
  const { contacts } = props.fields;

  const [errors, setErrors] = useState([]);

  const contact = {
    salutation: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    phone: "",
    mobile: formatDialCode(props.fields.address.country),
    email: ""
  };

  useEffect(() => {
    if (!contacts.length) props.onChange([contact]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddContact = () => {
    props.onChange([...contacts, contact]);
  };

  const handleRemoveContact = index => {
    contacts.splice(index, 1);
    props.onChange(contacts);

    if (errors.length > 1) {
      errors.splice(index, 1);
      setErrors(errors);
    }
  };

  const handleChange = (e, index) => {
    if (e.target.type === "tel") {
      e.target.value = e.target.value.replace(/[^\d.-]/g, "");
    }

    const { name, value } = e.target;
    const contact = { ...contacts[index], [name]: value };
    contacts.splice(index, 1, contact);

    props.onChange(contacts);
  };

  const handleSubmit = e => {
    e.preventDefault();

    const errors = contacts.map(validateContact);
    setErrors(errors);
    const errorCount = errors.reduce((acc, curr) => acc + curr.length, 0);

    if (errorCount > 0) {
      props.enqueueSnackbar(REQUIRED_FIELDS, { variant: "error" });
    } else {
      props.onSubmit();
    }
  };

  return (
    <section className="contact-details-form">
      <ul className="contacts-list">
        {contacts.map((fields, index) => {
          return (
            <li key={`contact-${index}`} className="contacts-list-item">
              <div className="heading">
                <h4>Contact {index + 1}</h4>

                {index === 0 ? (
                  <strong>(Primary Contact)</strong>
                ) : (
                  <button
                    type="button"
                    title="Delete Contact"
                    className="delete"
                    onClick={() => handleRemoveContact(index)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                )}
              </div>

              <Form
                key={`contact-${index}`}
                fields={fields}
                errors={errors[index] || []}
                onSubmit={e => e.preventDefault()}
                onChange={e => handleChange(e, index)}
                jobTitles={props.jobTitles}
              />
            </li>
          );
        })}
      </ul>

      <div className="row align-items-center">
        <div className="col col-md-6">
          <Button type="button" secondary onClick={props.onPrevStep}>
            Back
          </Button>
        </div>
        <div className="col col-md-6">
          <div className="add-new-wrap">
            <NewButton onClick={handleAddContact}>Add a New Contact</NewButton>
          </div>

          <Button type="button" onClick={handleSubmit}>
            Next
          </Button>
        </div>
      </div>
    </section>
  );
};

Contacts.propTypes = {
  fields: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
  jobTitles: PropTypes.array.isRequired,
  programme: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

export default withSnackbar(Contacts);
