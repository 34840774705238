import React from "react";
import PropTypes from "prop-types";
import Input from "components/Forms/Elements/Input";

const AttendeeGroup = ({ label, fields, errors, index, onChange, country }) => (
  <React.Fragment>
    <Input
      type="text"
      id="firstName"
      name="firstName"
      label={label}
      value={fields.firstName}
      onChange={onChange}
      error={errors.includes(`attendees.${index}.firstName`)}
      placeholder="First Name"
    />
    <Input
      type="text"
      id="lastName"
      name="lastName"
      label=""
      value={fields.lastName}
      onChange={onChange}
      error={errors.includes(`attendees.${index}.lastName`)}
      placeholder="Last Name"
    />
    <Input
      type="tel"
      id="mobile"
      name="mobile"
      label=""
      value={fields.mobile}
      onChange={onChange}
      placeholder="Mobile Number for booking confirmation"
    />
    <Input
      type="email"
      id="email"
      name="email"
      label=""
      value={fields.email}
      onChange={onChange}
      placeholder="Email Address"
    />
  </React.Fragment>
);

AttendeeGroup.propTypes = {
  label: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default AttendeeGroup;
