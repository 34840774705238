import React, { useState, useEffect, useContext } from "react";
import { gql } from "apollo-boost";

import Loading from "components/Loading";

import client from "utils/apollo";
import { Auth } from 'utils/auth';
import { isAdmin, isReadOnly } from "utils/roles";

import handleLogin from "./handleLogin";
import handleLogout from "./handleLogout";

// let auth = {onAuthStateChanged: (onChange) => {
//     window.setTimeout(onChange({uid: "knCtaVG4b6V0pzpyFK1VDfiV2gg1"}), 0);
// }};;

/**
 * Manage Users through Firebase Auth
 */

const UserContext = React.createContext();

export const Provider = ({ children }) => {
  const [state, setState] = useState({ loading: true, user: null });

  const handleUserChange = details => {
    setState({ ...state, user: { ...state.user, ...details } });
  };

  const onChange = async authUser => {
    const uId = authUser && authUser.attributes["custom:uid"];
    const user = authUser ? await getUserDetails(uId) : null;
    setState({ loading: false, user });
  };

  const localHandleLogin = handleLogin(onChange);
  const localHandleLogout = handleLogout(onChange);

  // Listen for auth state changes
  useEffect(() => {
     // const unsubscribe = Auth.onStateChanged(onChange);
    console.log("checking auth");
     if (state.user) {
       console.log("User already exists");
         return;
     }

     console.log("Checking current authenticated user");
     Auth.currentAuthenticatedUser()
         .then(user => {
             console.log("Successfully checked?");
             onChange(user);
         })
         .catch(err => {
             console.log("Checking current authenticated user fail");
             console.error(err);
             onChange(null);
         });

    // // unsubscribe to the listener when unmounting
    // return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider
      value={{ ...state, handleLogin: localHandleLogin, handleLogout: localHandleLogout, handleUserChange }}
    >
      {state.loading ? <Loading full /> : children}
    </UserContext.Provider>
  );
};

export const Consumer = UserContext.Consumer;

export const useSession = () => {
  const context = useContext(UserContext);

  return context ? context.user : null;
};

export const useLogout = () => {
  const { handleLogout } = useContext(UserContext);
  return handleLogout;
};

export const useUpdateUser = details => {
  const { handleUserChange } = useContext(UserContext);
  handleUserChange(details);
};

export default UserContext;

async function getUserDetails(id) {
  const query = gql`
    query User($id: ID!) {
      user(id: $id) {
        email
        fullName
        id
        approved
        created
        email
        firstName
        globalAdmin
        lastLogin
        lastName
        lockedOut
        notes
        role
        username
        fullName
        mediaPartner {
          id
          name
        }
      }
    }
  `;

  const result = await client.query({
    query,
    variables: { id },
    fetchPolicy: "network-only"
  });

  if (!result.data.user) return null;

  const { user } = result.data;

  user.admin = isAdmin(user.role);
  user.readOnly = user.globalAdmin ? false : isReadOnly(user.role);

  return user;
}

/**
 * Wrapper to change readOnly access for PM based upon Programme
 */
export const SetUserAccess = props => {
  const { handleUserChange, user } = useContext(UserContext);

  useEffect(() => {
    const shouldChangeReadOnly =
      !user.globalAdmin && user.role === "Project Manager";

    if (shouldChangeReadOnly) {
      const readOnly = user.id !== props.programme.projectManager.id;
      handleUserChange({ readOnly });
    }

    return () => {
      if (shouldChangeReadOnly) {
        handleUserChange({ readOnly: isReadOnly(user.role) });
      }
    };
  }, [props.programme]); // eslint-disable-line react-hooks/exhaustive-deps

  return props.children;
};
