import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

const Button = ({ href, className, secondary, small, children, ...atts }) => {
  const classes = classNames("button", className, { secondary, small });

  // Link
  if (href) {
    return (
      <Link
        to={{
          pathname: href,
          state: atts.state
        }}
        className={classes}
        {...atts}
      >
        {children}
      </Link>
    );
  }

  // Button
  return (
    <button className={classes} {...atts}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  secondary: PropTypes.bool,
  small: PropTypes.bool,
  href: PropTypes.string
};

export default Button;
