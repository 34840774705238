import lambda from "utils/lambda";

const sendEmail = async props => {
  const { fullName, email } = props.programme.projectManager;

  await lambda.call("sendEmail", {
    to: `${fullName} <${email}>`,
    from: "Evolve DMS Platform <dms@evolvemediasales.com>",
    subject: "Online Business Entry Submitted",
    html: `
		<p>Hi ${fullName},</p>
		<p>The following online business entry has been submitted:</p>
		<table>
			<tr>
				<td><strong>Programme Name:</strong></td>
				<td>${props.programme.name}</td>
			</tr>
			<tr>
				<td><strong>Business Name:</strong></td>
				<td>${props.name}</td>
			</tr>
			<tr>
				<td><strong>Account Manager:</strong></td>
				<td>${props.accountManager.fullName}</td>
			</tr>
		</table>
		<br />
		<p>Please <a href="https://dms.evolvemediasales.com/programme/${
      props.programme.id
    }/online-entries">click here</a> to review and approve this business</p>
		`
  });
};

export default sendEmail;
