const roles = {
  PROJECT_MANAGER: "Project Manager",
  PROGRAMME_DIRECTOR: "Programme Director",
  SYSTEMS_COORDINATOR: "Systems Coordinator",
  USER: "User"
};

export const isAdmin = role => {
  return [roles.PROJECT_MANAGER, roles.PROGRAMME_DIRECTOR].includes(role);
};

export const isReadOnly = role => {
  return [roles.PROGRAMME_DIRECTOR, roles.USER].includes(role);
};

export default roles;
