const getExportUrl = function (type){
    switch (type) {
        case 'graphql':
        	return 'https://i35czfk75j6ya555kzf6eihj3i0mvqhs.lambda-url.eu-west-2.on.aws/';
        	break;
        case 'exportJSON':
            return 'https://73ek4jbyinal6g5y56a4zm6fha0ixnhu.lambda-url.eu-west-2.on.aws/';
            break;
        case 'exportXLSX':
            return 'https://kos44ubprn3hu4wviepgkvxlw40oilty.lambda-url.eu-west-2.on.aws/';
            break;
        case 'getAddresses':
            return 'https://hgudnz4fno67z5jfingfd4xsfu0ayquk.lambda-url.eu-west-2.on.aws/';
            break;
        case 'sendSMS':
            return 'https://hjv3eomeopx3dq7jyxiimnslki0dwavj.lambda-url.eu-west-2.on.aws/';
            break;
        case 'uploadLogo':
            return 'https://aid7356o76sq3hsehdqnsirzby0ldfbm.lambda-url.eu-west-2.on.aws/';
            break;
        case 'exportXLSXEmail':
            return 'https://7njtk5va2imlafsqtelnc3lvja0qotcz.lambda-url.eu-west-2.on.aws/';
            break;
        case 'sendEmail':
            return 'https://omzgsnleejmpgtv2cklg6k7skm0anqka.lambda-url.eu-west-2.on.aws/';
            break;
        case 'exportXLSXSMS':
            return 'https://wvsercsf4y75lbsphrqaq377wu0uaoaq.lambda-url.eu-west-2.on.aws/';
            break;
        case 'deleteLogo':
            return 'https://yza5x5pkrp54xbniyu4kuyz4ku0voydi.lambda-url.eu-west-2.on.aws/';
            break;
        case 'memberships-change-handler':
            return 'https://injyfwcka5vjz3qbxtj5p4tfvi0wxoyk.lambda-url.eu-west-2.on.aws/';
            break;
        case 'getAddress':
            return 'https://zimuuumx4s6zx74ge5tqfqakda0ybkiw.lambda-url.eu-west-2.on.aws/';
            break;
        default:
            return null;
            break;
    }
}
exports.getExportUrl = getExportUrl;