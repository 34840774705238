import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSession } from "utils/UserContext";

const LoggedInRoute = props => {
  const user = useSession();
  if (!user) return <Redirect to="/login" />;

  return <Route {...props} />;
};

export default LoggedInRoute;
