import { useState } from "react";
import validator from "validator";

import client from "utils/apollo";
import { ADD_DRAFT_EVENT_BOOKING } from "utils/mutations";
import sendEmail from "./email";

const attendee = { firstName: "", lastName: "", mobile: "", email: "" };

const defaultFields = {
  business: {
    name: "",
    phone: ""
  },
  event: null,
  attendees: [attendee, attendee, attendee],
  comments: ""
};

const useOnlineBookingForm = props => {
  const [fields, setFields] = useState(defaultFields);
  const [errors, setErrors] = useState([]);
  const [submitting, isSubmitting] = useState(false);
  const [success, isSuccess] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;

    if (name.split(".").length === 2) {
      const [pre, att] = name.split(".");
      setFields({ ...fields, [pre]: { ...fields[pre], [att]: value } });
    } else {
      setFields({ ...fields, [name]: value });
    }
  };

  const handleAttendeeChange = (e, i) => {
    let { name, value } = e.target;

    const attendees = fields.attendees.map((attendee, index) => {
      if (index !== i) return attendee;

      // Format mobile numbers
      if (name === "mobile") value = value.replace(/[^\d.-]/g, "");

      return { ...attendee, [name]: value };
    });

    setFields({ ...fields, attendees });
  };

  const validateFields = () => {
    const errors = [];

    if (validator.isEmpty(fields.business.name)) errors.push("business.name");
    if (validator.isEmpty(fields.business.phone)) errors.push("business.phone");
    if (!fields.event) errors.push("event");

    // Attendee 1 - firstName lastName
    if (validator.isEmpty(fields.attendees[0].firstName)) {
      errors.push("attendees.0.firstName");
    }
    if (validator.isEmpty(fields.attendees[0].lastName)) {
      errors.push("attendees.0.lastName");
    }

    if (!validator.isEmpty(fields.attendees[1].firstName) || !validator.isEmpty(fields.attendees[1].lastName) || !validator.isEmpty(fields.attendees[1].mobile)) {

      if(validator.isEmpty(fields.attendees[1].firstName)){
        errors.push("attendees.1.firstName");
      }

      if(validator.isEmpty(fields.attendees[1].lastName)){
        errors.push("attendees.1.lastName");
      }

      if(validator.isEmpty(fields.attendees[1].mobile)){
        errors.push("attendees.1.mobile");
      }

    }

    if (!validator.isEmpty(fields.attendees[2].firstName) || !validator.isEmpty(fields.attendees[2].lastName) || !validator.isEmpty(fields.attendees[2].mobile)) {

      if(validator.isEmpty(fields.attendees[2].firstName)){
        errors.push("attendees.2.firstName");
      }

      if(validator.isEmpty(fields.attendees[2].lastName)){
        errors.push("attendees.2.lastName");
      }

      if(validator.isEmpty(fields.attendees[2].mobile)){
        errors.push("attendees.2.mobile");
      }

    }

    setErrors(errors);

    return !errors.length;
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!validateFields()) return;

    try {
      isSubmitting(true);

      const booking = {
        ...fields,
        attendees: fields.attendees.filter(a => !!a.firstName)
      };

      // Add Booking to DB
      await client.mutate({
        mutation: ADD_DRAFT_EVENT_BOOKING,
        variables: {
          programme: props.programme.id,
          booking
        }
      });

      // Display Success Page
      isSuccess(true);

      // Send Email to Project Manager
      sendEmail({ name: booking.business.name, programme: props.programme });
    } catch (err) {
      props.enqueueSnackbar(err.message, { variant: "error" });
    } finally {
      isSubmitting(false);
    }
  };

  const actions = { handleChange, handleAttendeeChange, handleSubmit };
  return [fields, errors, submitting, success, actions];
};

export default useOnlineBookingForm;
