import React from "react";
import PropTypes from "prop-types";

import Input from "components/Forms/Elements/Input";
import Select from "components/Forms/Elements/Select";
import TextArea from "components/Forms/Elements/TextArea";
import Button from "components/Button";
import AttendeeGroup from "./AttendeeGroup";

const Form = ({ fields, errors, submitting, actions, events }) => (
  <form onSubmit={actions.handleSubmit} className="online-booking-form__form">
    <Input
      type="text"
      id="business.name"
      name="business.name"
      label="Business Name"
      value={fields.business.name}
      onChange={actions.handleChange}
      error={errors.includes("business.name")}
    />

    <Input
      type="text"
      id="business.phone"
      name="business.phone"
      label="Business Phone"
      value={fields.business.phone}
      onChange={actions.handleChange}
      error={errors.includes("business.phone")}
    />

    <Select
      id="event"
      name="event"
      label="Event"
      value={fields.event}
      onChange={actions.handleChange}
      error={errors.includes("event")}
      options={events.map(({ id, fullName }) => ({
        value: id,
        label: fullName
      }))}
    />

    <AttendeeGroup
      label="Attendee 1"
      fields={fields.attendees[0]}
      errors={errors}
      index={0}
      onChange={e => actions.handleAttendeeChange(e, 0)}
    />

    <AttendeeGroup
      label="Attendee 2"
      fields={fields.attendees[1]}
      errors={errors}
      index={1}
      onChange={e => actions.handleAttendeeChange(e, 1)}
    />

    <AttendeeGroup
      label="Attendee 3"
      fields={fields.attendees[2]}
      errors={errors}
      index={2}
      onChange={e => actions.handleAttendeeChange(e, 2)}
    />

    <TextArea
      type="text"
      id="comments"
      name="comments"
      label="Comments"
      value={fields.comments}
      onChange={actions.handleChange}
      error={errors.includes("comments")}
    />

    <div className="form-actions">
      <Button type="submit" disabled={submitting}>
        {submitting ? "Submitting..." : "Submit"}
      </Button>
    </div>
  </form>
);

Form.propTypes = {
  fields: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    handleAttendeeChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
  }),
  events: PropTypes.array.isRequired
};

export default Form;
