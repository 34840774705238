import React, { useState, useEffect } from "react";
import moment from "moment";
import Loading from "components/Loading";
import { getAsset } from "utils/storage";

// HOC To get programme logo before mounting report
export const withLogo = Component => {
  return props => {
    const [logo, setLogo] = useState(false);

    useEffect(() => {
      const fetchLogo = async () => {
        if (props.programme.logo) {
          const logo = await getAsset(props.programme.logo);
          setLogo(logo);
        } else {
          setLogo(null);
        }
      };

      fetchLogo();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (logo === false) return <Loading full />;

    props.programme.logo = logo;
    return <Component {...props} />;
  };
};

// Calculate Percentage
export const percentage = (numerator, denominator, decimals = 0) => {
  if (denominator === 0) return "0%";
  return `${Math.round((numerator / denominator) * 100).toFixed(decimals)}%`;
};

export const sortByFullName = array => {
  return array.sort((a, b) => {
    const nameA = a.fullName.toLowerCase();
    const nameB = b.fullName.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};

export const groupByAccountManagers = businesses => {
  const accountManagers = businesses.reduce((acc, business) => {
    const index = acc.findIndex(a => a.id === business.accountManager.id);

    if (index > -1) {
      acc[index].businesses.push(business);
    } else {
      acc.push({ ...business.accountManager, businesses: [business] });
    }

    return acc;
  }, []);

  return sortByFullName(accountManagers);
};

export const groupByEventDay = events => {
  // Sort Events by date ASC
  events.sort((a, b) => new Date(a.date) - new Date(b.date));

  return events.reduce((acc, event) => {
    const day = moment.utc(event.date).format("YYYY-MM-DD");

    const index = acc.findIndex(a => a.day === day && a.name === event.name);

    if (index > -1) {
      acc[index].events.push(event);
    } else {
      acc.push({ day, date: event.date, name: event.name, events: [event] });
    }

    return acc;
  }, []);
};
