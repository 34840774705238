import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

const Input = React.forwardRef((props, ref) => {
  const { label, error, leftAlign, warning, ...atts } = props;

  const classes = classNames("form-element", "input", {
    error,
    warning,
    leftAlign
  });

  return (
    <div className={classes}>
      <label htmlFor={atts.id}>{label}</label>

      <input
        ref={ref}
        className={classNames("element", atts.className)}
        {...atts}
      />

      {error && (
        <div className="icon icon-error">
          <FontAwesomeIcon icon={faExclamation} />
        </div>
      )}
    </div>
  );
});

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  leftAlign: PropTypes.bool
};

export default Input;
