import gql from "graphql-tag";

export const VALIDATE_BUSINESS = gql`
  mutation ValidateBusiness(
    $accountManager: ID
    $address: AddressInput
    $budget: ID
    $factFind: FactFindInput
    $industry: ID
    $level: String
    $name: String
    $phone: String
    $primary: ID
    $website: String
  ) {
    validateBusiness(
      accountManager: $accountManager
      address: $address
      budget: $budget
      factFind: $factFind
      industry: $industry
      level: $level
      name: $name
      phone: $phone
      primary: $primary
      website: $website
    ) {
      name
    }
  }
`;

export const VALIDATE_CONTACT = gql`
  mutation ValidateContact(
    $email: String
    $firstName: String
    $lastName: String
    $jobTitle: ID
    $mobile: String
    $phone: String
    $salutation: String
  ) {
    validateContact(
      email: $email
      firstName: $firstName
      lastName: $lastName
      jobTitle: $jobTitle
      mobile: $mobile
      phone: $phone
      salutation: $salutation
    )
  }
`;

export const ADD_BUSINESS = gql`
  mutation AddBusiness(
    $programme: ID!
    $details: BusinessInput
    $contacts: [ContactInput]
  ) {
    addBusiness(programme: $programme, details: $details, contacts: $contacts) {
      id
    }
  }
`;

export const UPDATE_BUSINESS = gql`
  mutation UpdateBusiness(
    $programme: ID!
    $details: BusinessInput
    $booking: EventBookingInput
    $goLiveProcess: GoLiveProcessInput
    $activity: ActivityInput
  ) {
    updateBusiness(
      programme: $programme
      details: $details
      booking: $booking
      goLiveProcess: $goLiveProcess
      activity: $activity
    ) {
      id
    }
  }
`;

export const ADD_DRAFT_EVENT_BOOKING = gql`
  mutation AddDraftEventBooking(
    $programme: ID!
    $booking: DraftEventBookingInput
  ) {
    addDraftEventBooking(programme: $programme, booking: $booking) {
      id
    }
  }
`;

export const OVERWRITE_EVENT_BOOKING = gql`
  mutation OverwriteEventBooking(
    $businessID: ID!
    $bookingID: ID!
    $programme: ID!
    $fields: DraftEventBookingInput
  ) {
    overwriteEventBooking(
      businessID: $businessID
      bookingID: $bookingID
      programme: $programme
      fields: $fields
    ) {
      id
    }
  }
`;
