import { gql } from "apollo-boost";

import client from "utils/apollo";
import { Auth } from 'utils/auth';
import { LOGIN } from "utils/messages";
import { isAdmin } from "utils/roles";


const handleLogin = onLoggedIn => {
    return async ({ username, password }) => {
        // Attempt login
        try {
            var user = await Auth.signIn(username, password);
            onLoggedIn(user);
        } catch (err) {
            console.error(err);
            if (err.code === "auth/too-many-requests") {
                return LOGIN.UNAPPROVED;
            } else {
                try {
                    const user = await getUserByUsername(username);
                    if (user) {
                        await updateLoginAttempts(user.id);
                    }
                } finally {
                    return LOGIN.ERROR;
                }
            }
        }

        try {
            const user = await getUserByUsername(username);
            if (!isAdmin(user.role)) {
                // User is not approved
                if (!user.approved) return LOGIN.UNAPPROVED;

                // User is locked out
                if (user.lockedOut) return LOGIN.ACCOUNT_LOCKED;
            }
        } catch (err) {
            return LOGIN.ERROR;
        }
    };
};

export default handleLogin;

// Retrieve a user by username
// @param username {String}
async function getUserByUsername(username) {
  const query = gql`
    query User($username: String!) {
      user(username: $username) {
        id
        approved
        email
        lockedOut
        role
      }
    }
  `;

  const result = await client.query({
    query,
    variables: { username },
    fetchPolicy: "network-only"
  });

  return result.data.user;
}

// Add a login attempt on user's account
// @param user ID
async function updateLoginAttempts(id) {
  const mutation = gql`
    mutation UpdateLoginAttempts($id: ID!) {
      updateLoginAttempts(id: $id)
    }
  `;

  await client.mutate({ mutation, variables: { id } });
}
