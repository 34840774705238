export default (error, small = false, centered = false) => ({
  control: (provided, state) => {
    let styles = {
      ...provided,
      fontSize: "1.2rem",
      padding: small ? "0" : "0.3rem",
      borderColor: state.isFocused ? "#838c8e" : "#ddd",
      boxShadow: "none",
      "&:hover": {
        borderColor: state.isFocused ? "#838c8e" : "#ddd"
      }
    };

    if (error) {
      styles.borderColor = "#ff6666";
      styles["&:hover"] = "#ff6666";
    }

    return styles;
  },
  menu: provided => ({
    ...provided,
    textAlign: centered ? "center" : "left"
  }),
  placeholder: provided => {
    let styles = {
      ...provided,
      color: "#3c3d41"
    };

    if (centered) {
      styles.left = "50%";
      styles.transform = "translate(-50%, -50%)";
    }

    return styles;
  },
  input: provided => {
    let styles = provided;
    if (centered) styles.margin = "auto";
    return styles;
  },
  singleValue: provided => {
    let styles = provided;

    if (centered) {
      styles.left = "50%";
      styles.transform = "translate(-50%, -50%)";
    }

    return styles;
  }
});
