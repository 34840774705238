import ApolloClient from "apollo-boost";
import { Auth } from 'aws-amplify';

import lambda from "utils/lambda";

console.log(process.env.APOLLO_GRAPHQL_URL);

const client = new ApolloClient({
  uri: window.location.hostname === 'localhost' ? 'http://localhost:4000' : lambda.getUrl('graphql'),
  request: async (operation) => {
    try {
      const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
      operation.setContext({
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      });
    } catch (err) {
      console.error(err);
    }
  },
});

export default client;