import React, { useState } from "react";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
// import { db } from "utils/firebase";
import { BUSINESSES, REQUIRED_FIELDS } from "utils/messages";
import { validateBusiness } from "../validate";
import Form from "./Form";

let db;

const Details = props => {
  const [duplicate, isDuplicate] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleBusinessNameChange = async e => {
    if (props.duplicateCheck) {
      const duplicate = await checkBusinessNameAvailability(
        e.target.value,
        props.programme.id
      );
      isDuplicate(duplicate);
      if (duplicate) {
        props.enqueueSnackbar(BUSINESSES.NAME_TAKEN, { variant: "warning" });
      }
    }
  };

  const handleChange = e => {
    if (e.target.type === "tel") {
      e.target.value = e.target.value.replace(/[^\d.-]/g, "");
    }

    if (e.target.name === "address.postcode") {
      e.target.value = e.target.value.toUpperCase();
    }

    props.onChange(e);
  };

  const handleSubmit = e => {
    e.preventDefault();

    const errors = validateBusiness(props.fields);

    if (errors.length) {
      setErrors(errors);
      props.enqueueSnackbar(REQUIRED_FIELDS, { variant: "error" });
    } else {
      props.onSubmit();
    }
  };

  return (
    <section className="business-details">
      <Form
        {...props}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onBusinessNameChange={handleBusinessNameChange}
        duplicate={duplicate}
        errors={errors}
      />
    </section>
  );
};

Details.propTypes = {
  fields: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  levels: PropTypes.array.isRequired,
  budgets: PropTypes.array.isRequired,
  industries: PropTypes.array.isRequired,
  accountManagers: PropTypes.array.isRequired,
  programme: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  duplicateCheck: PropTypes.bool.isRequired
};

export default withSnackbar(Details);

async function checkBusinessNameAvailability(name, programme) {
  if (!name) return false;

  // Get all businesses in programme
  const query = await db
    .collection("programmes")
    .doc(programme)
    .collection("businesses")
    .get();

  if (query.empty) return false;

  const matches = query.docs.filter(doc => {
    const docName = doc
      .data()
      .name.trim()
      .toLowerCase();

    return docName === name.trim().toLowerCase();
  });

  return matches.length > 0;
}
