import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Query as ApolloQuery } from "react-apollo";
import { withSnackbar } from "notistack";
import Loading from "components/Loading";
import { useSession } from "utils/UserContext";

const QueryChild = props => {
  console.log("Error");
  if(typeof props.error !== "undefined"){
    if(props.error.indexOf('Received status code 400') > -1){
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  }
  useMemo(() => {
    if (props.error) {
      props.enqueueSnackbar(props.error.message, {
        variant: "error",
        preventDuplicate: true
      });
    }
  }, [props]);

  return props.error ? null : props.children(props.data, props.refetch);
};

const Query = props => {
  const user = useSession();

  return (
    <ApolloQuery {...props} fetchPolicy={props.fetchPolicy || "network-only"}>
      {({ loading, error, data, refetch }) => {
        if (props.useLocalStorage) {
          const key = `${user.id}_${props.useLocalStorage}`;
          if (data) {
            const dataString = JSON.stringify(data);

            window.localStorage.setItem(key, dataString);
          } else {
            const cachedDataString = window.localStorage.getItem(key);

            if (cachedDataString) {
              const cachedData = JSON.parse(cachedDataString);

              return (
                <QueryChild
                  error={error}
                  data={cachedData}
                  isCachedData={true}
                  refetch={refetch}
                  {...props}
                />
              );
            }
          }
        }

        if (loading) {
            if (!props.hideLoading) {
                return <Loading full={props.full} />;
            } else {
                return null;
            }
        }

        return (
          <QueryChild error={error} data={data} refetch={refetch} {...props} />
        );
      }}
    </ApolloQuery>
  );
};

Query.propTypes = {
  query: PropTypes.object.isRequired,
  full: PropTypes.bool,
  useLocalStorage: PropTypes.string,
};

export default withSnackbar(Query);
