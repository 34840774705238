module.exports = {
  UNAUTHORIZED: "Unauthorized access",
  BAD_REQUEST: "Bad request",
  ERROR_OCCURED: "An error occurred. Please try again",
  REQUIRED_FIELDS: "Please fill out all required fields",
  NOT_FOUND: "%s not found",
  UPDATE_SUCCESS: "%s has been updated",
  CREATE_SUCCESS: "%s has been created",
  DELETE_SUCCESS: "%s has been deleted",
  DUPLICATE_SUCCESS: "%s has been duplicated",
  COPY_SUCCESS: "%s have been copied",

  LOGIN: {
    REQUIRED: "Please enter a username and password",
    ERROR: "Invalid username or password",
    ACCOUNT_LOCKED:
      "Unfortunately your account details are incorrect. Please contact your Project Manager to reactivate your account",
    UNAPPROVED:
      "Unfortunately you are not able to access the system at this time. Please get in touch with your Project Manager for support"
  },
  PASSWORD: {
    ERROR: "Old password is invalid",
    LENGTH: "Password must be at least 6 characters",
    SUCCESS:
      "Password has been updated. Please login in using your new password"
  },
  USERS: {
    USERNAME_TAKEN: "Username not available",
    EMAIL_TAKEN: "Email address not available",
    ASSIGNED_ADMIN:
      "Cannot delete user as they are assigned to the following programmes:",
    APPROVED_SUCCESS: "Updated approved status for %s",
    LOCKED_OUT_SUCCESS: "Updated locked out status for %s"
  },
  UPLOAD: {
    NOT_FOUND: "No files were uploaded",
    SUCCESS: "File Uploaded"
  },
  BUDGETS: {
    MAX_ERROR: 'Please enter a maximum budget, or select "No Maximum"',
    MIN_MAX: "Minimum budget cannot be greater than maximum budget"
  },
  ADDRESS_LOOKUP: {
    EMPTY: "No addresses found, please try a different search"
  },
  BUSINESSES: {
    NAME_TAKEN: "This business is already registered"
  },
  MEMBERSHIPS: {
    NO_SOLD_ERROR: 'Cannot set "No Sold" as memberships have been added'
  },
  BUSINESS_ADD: "Thank you for submitting these business details.",
  BUSINESS_ADD_REVIEW:
    "This information will be reviewed shortly and once approved, will be added to your guest list for the event. If you wish to make any changes to this information, please get in touch with your Project Manager from the Evolve Team"
};
