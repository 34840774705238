import { useState, useEffect } from "react";
import defaultFields from "./defaultFields";
import sendEmail from "./email";

import client from "utils/apollo";
import { ADD_BUSINESS } from "utils/mutations";
import { BUSINESS_ADD, BUSINESS_ADD_REVIEW } from "utils/messages";

const useNewBusinessWizard = props => {
  const { country } = props.programme.mediaPartner;

  const [step, setStep] = useState(0);
  const [fields, setFields] = useState(defaultFields);
  const [submitting, isSubmitting] = useState(false);

  useEffect(() => {
    setFields({ ...fields, address: { ...fields.address, country } });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = e => {
    const { name, value } = e.target;

    if (name.split(".").length === 2) {
      const [pre, att] = name.split(".");
      setFields({ ...fields, [pre]: { ...fields[pre], [att]: value } });
    } else {
      setFields({ ...fields, [name]: value });
    }
  };

  const handleContactsChange = contacts => {
    setFields({ ...fields, contacts });
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleReset = () => {
    setStep(0);

    const resetFields = { ...defaultFields };
    resetFields.address.country = country;
    setFields(resetFields);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      isSubmitting(true);

      const { contacts, ...details } = fields;

      // Add the Business to DB
      await client.mutate({
        mutation: ADD_BUSINESS,
        variables: {
          programme: props.programme.id,
          details,
          contacts
        }
      });

      // Display Success Notification
      props.enqueueSnackbar(BUSINESS_ADD + BUSINESS_ADD_REVIEW, {
        variant: "success",
        autoHideDuration: 12000
      });

      // Reset Form
      handleReset();

      // Send Email to Project Manager
      sendEmail({
        name: details.name,
        programme: props.programme,
        accountManager: props.accountManagers.find(
          am => am.id === details.accountManager
        )
      });
    } catch (err) {
      props.enqueueSnackbar(err.message, { variant: "error" });
    } finally {
      isSubmitting(false);
    }
  };

  const actions = {
    handleChange,
    handleContactsChange,
    handleNextStep,
    handlePrevStep,
    handleSubmit
  };

  return [fields, step, submitting, actions];
};

export default useNewBusinessWizard;
