import { Auth } from "utils/auth";

const handleLogout = onLoggedOut => {
    return async () => {
      try {
        await Auth.signOut();

        onLoggedOut(null);
      } catch (err) {
        console.error(err);
      }
    };
};

export default handleLogout;
