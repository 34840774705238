import React from "react";
import { render } from "react-dom";

import "typeface-montserrat";
import "reset-css";
import "styles/reset.scss";

import App from "./App";

render(<App />, document.getElementById("root"));
