import { Auth } from 'aws-amplify';
import { getExportUrl } from './utils';

const getUrl = function(func){
	return getExportUrl(func) ?? `https://2lpn85ziq1.execute-api.eu-west-2.amazonaws.com/dev/${func}`;
};

const call = async (func, data) => {
	const session = await Auth.currentSession();
	const accessToken = session.getAccessToken().getJwtToken();
	const headers = { Authorization: `Bearer ${accessToken}` };

	const res = await fetch(
		getUrl(func),
		{
			method: "POST",
			body: JSON.stringify(data),
			headers: headers
		}
	);

    let text = await res.text();

    try {
        return JSON.parse(text);
    } catch (e) {
        return text;
    }
};

export default { call, getUrl, getExportUrl };