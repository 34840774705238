import React, { useState, useContext } from "react";

const AppContext = React.createContext();

export const Provider = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <AppContext.Provider value={{ menu: { collapsed, setCollapsed } }}>
      {children}
    </AppContext.Provider>
  );
};

export const useMessage = () => {
  const { setMessage } = useContext(AppContext);
  return setMessage;
};

export const useMenuCollapse = () => {
  const { menu } = useContext(AppContext);
  return menu;
};

export default AppContext;
