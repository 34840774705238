import React from "react";
import gql from "graphql-tag";
import { withSnackbar } from "notistack";

import Query from "components/Query";
import { withLogo } from "pages/Reports/utils";
import useOnlineBookingForm from "./useOnlineBookingForm";
import Form from "./Form";

import "styles/online-booking-form.scss";

const OnlineBookingForm = props => {
  const [fields, errors, submitting, success, actions] = useOnlineBookingForm(
    props
  );

  return (
    <div className="online-booking-form">
      {props.programme.logo && (
        <img
          className="online-booking-form__logo"
          src={props.programme.logo}
          alt={props.programme.name}
        />
      )}

      {success ? (
        <div className="online-booking-form__success">
          <p>Thank you for submitting these booking details.</p>
        </div>
      ) : (
        <React.Fragment>
          <div className="online-booking-form__text">
            <h2>Simply choose the day and time that suits you best...</h2>

            <p>Please complete your details below</p>
          </div>

          <Form
            fields={fields}
            errors={errors}
            submitting={submitting}
            actions={actions}
            events={props.events}
          />
        </React.Fragment>
      )}
    </div>
  );
};

const ONLINE_BOOKING_FORM_QUERY = gql`
  query OnlineBookingFormQuery($id: ID!) {
    programme(id: $id) {
      id
      name
      logo
      projectManager {
        id
        fullName
        email
      }
    }
    events(programme: $id) {
      id
      fullName
    }
  }
`;

export default withSnackbar(props => {
  const { id } = props.match.params;

  return (
    <Query query={ONLINE_BOOKING_FORM_QUERY} variables={{ id }} full>
      {withLogo(data => (
        <OnlineBookingForm {...props} {...data} />
      ))}
    </Query>
  );
});
