import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

const TextArea = React.forwardRef((props, ref) => {
  const { label, error, leftAlign, ...atts } = props;

  const classes = classNames("form-element", "textarea", { error, leftAlign });

  atts.value = atts.value === null ? "" : atts.value;

  return (
    <div className={classes}>
      <label htmlFor={atts.id}>{label}</label>
      <textarea
        ref={ref}
        className={classNames("element", atts.className)}
        {...atts}
      />

      {error && (
        <div className="icon icon-error">
          <FontAwesomeIcon icon={faExclamation} />
        </div>
      )}
    </div>
  );
});

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool
};

export default TextArea;
