import Amplify, { Auth } from 'aws-amplify';

Amplify.configure({
    Auth: {
        region: 'eu-west-2',
        userPoolId: 'eu-west-2_ALRQSVbyo',
        userPoolWebClientId: '6eg21tmbbatdccpstbs1nuhfu0',
        mandatorySignIn: false,
        cookieStorage: {
            domain: window.location.hostname,
            path: '/',
            expires: 365,
            secure: false
        },
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    }
});

export { Auth };
