import React from "react";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";

import Details from "./Details";
import Contacts from "./Contacts";
import FactFind from "./FactFind";
import useNewBusinessWizard from "./useNewBusinessWizard";

const NewBusinessWizard = props => {
  const [fields, step, submitting, actions] = useNewBusinessWizard(props);

  const steps = [
    <Details
      fields={fields}
      onChange={actions.handleChange}
      onSubmit={actions.handleNextStep}
      levels={props.levels}
      budgets={props.budgets}
      industries={props.industries}
      accountManagers={props.accountManagers}
      programme={props.programme}
      duplicateCheck={props.duplicateCheck}
    />,
    <Contacts
      fields={fields}
      onChange={actions.handleContactsChange}
      onSubmit={actions.handleNextStep}
      onPrevStep={actions.handlePrevStep}
      jobTitles={props.jobTitles}
    />,
    <FactFind
      fields={fields}
      onChange={actions.handleChange}
      onSubmit={actions.handleSubmit}
      onPrevStep={actions.handlePrevStep}
      submitting={submitting}
      activities={props.activities}
    />
  ];

  return <div className="new-business-wizard">{steps[step]}</div>;
};

NewBusinessWizard.propTypes = {
  programme: PropTypes.shape({
    id: PropTypes.string.isRequired,
    mediaPartner: PropTypes.shape({
      country: PropTypes.string.isRequired
    })
  }),
  levels: PropTypes.array.isRequired,
  budgets: PropTypes.array.isRequired,
  industries: PropTypes.array.isRequired,
  accountManagers: PropTypes.array.isRequired,
  jobTitles: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  duplicateCheck: PropTypes.bool.isRequired
};

export default withSnackbar(NewBusinessWizard);
