import React from "react";
import gql from "graphql-tag";

import Query from "components/Query";
import NewBusinessWizard from "components/NewBusinessWizard";
import { withLogo } from "pages/Reports/utils";
import evolveLogo from "images/evolve-logo.gif";

import "styles/online-business-form.scss";

const OnlineBusinessForm = props => {
  return (
    <div className="online-business-form">
      <header className="obf-header">
        <div className="container">
          <div className="logos">
            <img src={props.programme.logo} alt={props.programme.name} />
            <img src={evolveLogo} alt="Evolve Media Sales" />
          </div>

          <h1>New Business Entry Form</h1>
        </div>
      </header>

      <main className="obf-main">
        <div className="container">
          <NewBusinessWizard
            programme={props.programme}
            levels={props.levels.filter(({ value }) => {
              return ["vip", "standard"].includes(value);
            })}
            budgets={props.budgets}
            industries={props.industries}
            accountManagers={props.accountManagers}
            jobTitles={props.jobTitles}
            activities={props.activities}
            duplicateCheck={false}
          />
        </div>
      </main>
    </div>
  );
};

const ONLINE_BUSINESS_FORM_QUERY = gql`
  query OnlineBusinessFormQuery($id: ID!) {
    programme(id: $id) {
      id
      name
      logo
      mediaPartner {
        id
        country
      }
      projectManager {
        id
        fullName
        email
      }
    }
    accountManagers(programme: $id) {
      id
      fullName
    }
    budgets(programme: $id) {
      id
      name
    }
    activities {
      id
      name
    }
    industries {
      id
      name
    }
    jobTitles {
      id
      name
    }
    levels {
      label
      value
    }
  }
`;

export default props => {
  const { id } = props.match.params;

  return (
    <Query query={ONLINE_BUSINESS_FORM_QUERY} variables={{ id }} full>
      {withLogo(data => (
        <OnlineBusinessForm {...props} {...data} />
      ))}
    </Query>
  );
};
