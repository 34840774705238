import React, { useState, useEffect, useContext } from "react";

import { getAsset } from "utils/storage";
import Loading from "components/Loading";

/**
 * Manage Individual Programme Context
 */
const ProgrammeContext = React.createContext();

/**
 * Programme Provider wraps entire application
 * So Layout can access useProgramme()
 */
export const Provider = ({ children }) => {
  const [programme, setProgramme] = useState(null);

  const handleProgrammeChange = programme => {
    setProgramme(programme);
  };

  return (
    <ProgrammeContext.Provider
      value={{
        programme,
        handleProgrammeChange
      }}
    >
      {children}
    </ProgrammeContext.Provider>
  );
};

/**
 * Helper to get current Programme
 * @returns object || null
 */
export const useProgramme = () => {
  const { programme } = useContext(ProgrammeContext);
  return programme || null;
};

/**
 * Helper to get current Programme's Logo from Cloud Storage
 * @returns image src
 */
export const useProgrammeLogo = () => {
  const programme = useProgramme();
  const [logo, setLogo] = useState(false);

  useEffect(() => {
    const fetchLogo = async () => {
      if (programme) {
        const logo = await getAsset(programme.logo);
        setLogo(logo);
      } else {
        setLogo(null);
      }
    };

    fetchLogo();
  }, [programme]);

  return logo;
};

export default ProgrammeContext;

// HOC To get programme logo before mounting report
export const withProgrammeLogo = Component => {
  return props => {
    const logo = useProgrammeLogo();

    if (logo === false) return <Loading />;

    return <Component {...props} logoSrc={logo} />;
  };
};

/**
 * Wrapper to await the programme being set in context
 */
export const SetProgramme = props => {
  const { handleProgrammeChange, programme } = useContext(ProgrammeContext);

  useEffect(() => {
    handleProgrammeChange(props.programme);
    return () => handleProgrammeChange(null);
  }, [props.programme, handleProgrammeChange]);

  return !programme ? <Loading /> : props.children;
};
