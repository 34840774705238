import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ReactComponent as Icon } from "./plus-icon.svg";

const NewButton = ({ to, children, ...atts }) => {
  return to ? (
    <Link
      to={{
        pathname: to,
        state: atts.state
      }}
      className="add-new"
    >
      <Icon />
      {children}
    </Link>
  ) : (
    <button {...atts} className="add-new">
      <Icon />
      {children}
    </button>
  );
};

NewButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any.isRequired
};

export default NewButton;
