import moment from "moment";
import countries from "utils/countries.json";

/**
 * Format Date/Times
 */
export const formatDateTime = (timestamp, format = "Do MMMM YYYY, h:mm A") => {
  if (!timestamp) return "";

  const date = new Date(timestamp);
  return moment.utc(date).format(format);
};

export const formatDay = timestamp => {
  return formatDateTime(timestamp, "dddd");
};

export const formatDate = timestamp => {
  return formatDateTime(timestamp, "Do MMMM YYYY");
};

export const formatTime = timestamp => {
  return formatDateTime(timestamp, "h:mm A");
};

export const toDatetimeLocal = timestamp => {
  if (!timestamp) return "";

  const date = new Date(timestamp);
  return moment.utc(date).format("YYYY-MM-DDTHH:mm:ss");
};

export const formatCountryCode = value => {
  const country = countries.find(c => c.value === value);
  return country ? country.code : "";
};

export const formatDialCode = value => {
  const country = countries.find(c => c.value === value);
  return country ? country.dial_code : "";
};
