import validator from "validator";
import get from "lodash/get";

export const validateBusiness = fields => {
  const errors = [];

  const required = [
    "accountManager",
    "address.line1",
    "address.town",
    "address.country",
    "budget",
    "industry",
    "level",
    "name",
    "phone"
  ];

  required.forEach(key => {
    if (!get(fields, key) || validator.isEmpty(get(fields, key))) {
      errors.push(key);
    }
  });

  // Postcode Required in UK
  const country = get(fields, "address.country");
  const postcode = get(fields, "address.postcode");
  if (country === "United Kingdom" && !postcode) {
    errors.push("address.postcode");
  }

  return errors;
};

export const validateContact = fields => {
  const errors = [];

  if (validator.isEmpty(fields.firstName)) errors.push("firstName");
  if (validator.isEmpty(fields.lastName)) errors.push("lastName");
  if (!fields.jobTitle) errors.push("jobTitle");

  return errors;
};
